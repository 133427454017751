import axios from "axios";
import api from "./index";

export const login = async (email, password) => {
  return await axios.post("/api/v1/auth/login", {email, password});
};
export const getRefreshToken = async (token) => {
  return await axios.get("/api/v1/auth/refresh", {
    headers: {
      authorization: "Bearer " + token,
    },
  });
};
export const getUsers = async (page) => {
  return await api.get(`/api/v1/admin/users?page=${page}&order=DESC`);
}

export const deleteUser = async (id) => {
  return await api.delete(`/api/v1/admin/users/${id}`);
}
export const getDreams = async (page) => {
  return await api.get(`/api/v1/admin/dreams?page=${page}&order=DESC`);
}
export const deleteDream = async (id) => {
  return await api.delete(`/api/v1/admin/dreams/${id}`);
}
export const getDonations = async (page) => {
  return await api.get(`/api/v1/admin/donations?page=${page}&order=DESC`);
}
export const getCharities = async (page) => {
  return await api.get(`/api/v1/admin/charities?order=DESC&page=${page}`);
}
export const deleteCharity = async (id) => {
  return await api.delete(`/api/v1/admin/charities/${id}`);
}
export const createCharity = async (title, description, amount) => {
  return await api.post(`/api/v1/admin/charities`, {title, description, amount});
}
export const uploadCharityImage = async (id, image) => {
  const formData = new FormData();
  formData.append("image", image);
  return await api.post(`/api/v1/admin/charity/${id}/image`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
export const uploadCharityImages = async (id, images) => {
  const formData = new FormData();
  images.forEach((file) => {
    formData.append("images", file);
  });
  return await api.post(`/api/v1/admin/charity/${id}/images`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
export const deleteCharityImage = async (charityId, imageId) => {
  return await api.delete(`/api/v1/admin/charity/${charityId}/image/${imageId}`);
}
export const updateCharity = async (id, title, amount) => {
  return await api.patch(`/api/v1/admin/charities/${id}`, {title, amount});
}
export const getCharityById = async (id) => {
  return await api.get(`/api/v1/charities/${id}`);
}
export const getWingDonations = async (page) => {
  return await api.get(`/api/v1/admin/wing-donations?page=${page}&order=DESC`);
}
export const getPublicDreamDonations = async (id, page) => {
  return await api.get(`/api/v1/donations/dreams/${id}?order=DESC&page=${page}`)
}
export const getPayouts = async (page) => {
  return await api.get(`/api/v1/admin/payouts?order=DESC&page=${page}`)
}
export const patchPayments = async (id, object) => {
  return await api.patch(`/api/v1/admin/payouts/${id}`, object)
}

export const restoreUser = async (id) => {
  return await api.patch(`/api/v1/admin/restore/users/${id}`)
}

export const restoreCharity = async (id) => {
  return await api.patch(`/api/v1/admin/restore/charities/${id}`)
}

export const restoreDream = async (id) => {
  return await api.patch(`/api/v1/admin/restore/dreams/${id}`)
}
export const getSearchedUsers = async (queryType, text) => {
  return await api.get(`/api/v1/users?${queryType}=${text}`);
};
export const getFeedbacks = async (page) => {
  return await api.get(`/api/v1/admin/feedbacks?page=${page}&order=DESC`);
};
export const postMessage = async (receiverId,title) => {
  return await api.post(`/api/v1/admin/notifications`,{receiverId,title});
};
export const exportUsers = async (startDate,endDate) => {
  return await api.get(`/api/v1/admin/export/users?startDate=${startDate}&endDate=${endDate}`,);
};

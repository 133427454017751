import React, {useEffect, useState} from 'react'
import moment from "moment";
import {
  Media,
  Table,
  Progress,
  Button,
  Card,
  CardBody,
  Col,
  CardHeader,
  Row,
} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {deleteDream, getDreams, getPublicDreamDonations, restoreDream} from "../../services/api";
import {setDreams, setInputValue, setAddDreams, setModalDonations} from "../../store/admin";
import InfiniteScroll from 'react-infinite-scroller-improve';
import loaderGif from '../../assets/images/page-load-loader.gif'
import DreamMakersModal from '../../components/DreamMakersModal'
import "../index.css"

let page2d = 1;
let loader = false;

const Dreams = () => {
  const [isShow, setIsShow] = useState(false)
  const dispatch = useDispatch();
  const [user, setUser] = useState({})
  const [dreamToDeactivate, setDreamToDeactivate] = useState('')
  const dreams = useSelector((state) => state.adminModule).dreams;
  const donations = useSelector((state) => state.adminModule).modalDonations;
  const [hasNextPage, setHasNextPage] = useState(true);
  const [dreamId, setDreamId] = useState('');
  const [showMore, setShowMore] = useState(true);

  useEffect(() => {
    getDreams(1).then((response) => {
      dispatch(setDreams(response?.data));
      setHasNextPage(response?.data?.meta?.hasNextPage)
      dispatch(setInputValue(''));
    })
  }, [])

  useEffect(() => () => {
    page2d = 1
    dispatch(setDreams({}))
    setHasNextPage(false)
  }, [])

  const deactivateDream = (id) => {
    deleteDream(id).then(() => {
      getDreams(1).then((response) => {
        dispatch(setDreams(response?.data))
        setHasNextPage(response?.data?.meta?.hasNextPage)
        page2d = 1;
      })
    })
  }
  const restoreUserById = (id) => {
    restoreDream(id).then(() => {
      getDreams(1).then((res) => {
        dispatch(setDreams(res?.data))
        setHasNextPage(res?.data?.meta?.hasNextPage)
        page2d = 1;
        dispatch(setInputValue(''));
      })
    })
  }



  const loadMore = () => {
    if (!!dreams?.results?.length) {
      if (!loader) {
        page2d += 1;
        loader = true;
        getDreams(page2d).then((response) => {
          dispatch(setAddDreams(response?.data))
          loader = false;
          setHasNextPage(response?.data?.meta?.hasNextPage)
        })
      }
    }
  }
  const progressClick = (id) => {
    setDreamId(id);
    getPublicDreamDonations(id, 1).then((res) => {
      dispatch(setModalDonations(res.data))
      setIsShow(true);
    })
  }
  const apiDreams = dreams?.results?.map((dream) => {
    return (
      <tr key={dream?.id}   style={{backgroundColor:`${dream?.deletedAt !== null ? 'iron' : 'white'}`}}>
        <th scope="row">
          <Media className="align-items-center justify-content-center">
            <div
              style={{cursor: 'pointer'}}
              data-toggle="modal"
              data-target={`#user-modal${dream.user?.id}`}>
              <img
                alt='img'
                className="avatar avatar-lg"
                style={{objectFit: "cover"}}
                src={dream.images?.find((image) => image.isMain === true)?.avatarUrl || 'https://www.kindpng.com/picc/m/24-248253_user-profile-default-image-png-clipart-png-download.png'}
              />
            </div>
          </Media>
        </th>
        <td>
          <div className='text-wrap my-span'>
            <span>{dream?.title} </span>
          </div>
        </td>
        <td>
          <span>
            {dream?.status}
          </span>
        </td>
        <td>
          <span>
            {dream?.amount}
          </span>
        </td>
        <td>
          <span>
            {dream?.amountReceived}
          </span>
        </td>
        <td>
          <div className="d-flex align-items-center">
            <div>
              <Progress
                min={0}
                max={dream?.amount}
                value={dream?.amountReceived}
                animated={true}
                barClassName="bg-danger"
                style={{height:'8px',cursor: 'pointer'}}
                onClick={() => progressClick(dream?.id)}
              />
            </div>
          </div>
        </td>
        <td>
          <a data-toggle="modal" data-target={`#user-modal`} onClick={() => setUser(dream?.user)}
             type="button">
                <span style={{color: 'blue'}} className="text-wrap">{dream.user?.firstName} {dream?.user?.lastName}
               </span>
          </a>
        </td>
        <td>
          <span className="text-wrap">{moment(dream?.createdAt).format('DD MMMM YYYY HH:mm')}</span>
        </td>
        <td>
          {dream?.deletedAt?
            <Button color="warning" data-toggle="modal"
                    data-target={`#restore-modal`}
                    type="button"
                    onClick={() => setDreamToDeactivate(dream?.id)}
            >
              Restore
            </Button> :
            <Button color="danger"
                    data-toggle="modal"
                    data-target={`#decline-modal`}
                    type="button"
                    onClick={() => setDreamToDeactivate(dream?.id)}>Deactivate</Button>
          }
        </td>
      </tr>
    )
  })
  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
      </div>
      <DreamMakersModal donations={donations} isShow={isShow} setIsShow={setIsShow} setUser={setUser} dreamId={dreamId}/>
      <InfiniteScroll
        loadMore={() => loadMore()}
        hasMore={hasNextPage}
        useWindow={true}
      >
        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
          <tr>
            <th scope="col">Dream Image:</th>
            <th scope="col">Title:</th>
            <th scope="col">Status:</th>
            <th scope="col">Amount:</th>
            <th scope="col">Received:</th>
            <th scope="col">Donations Received</th>
            <th scope="col">User:</th>
            <th scope="col">CreateAt:</th>
            <th scope="col">{`Total dreams(${dreams?.meta?.itemCount || 0})`}</th>
          </tr>
          </thead>
          <tbody>
          {apiDreams}
          </tbody>
        </Table>
        {(loader &&
          <div className="d-flex justify-content-center w-100" style={{width: '100%'}}>
            <img
              src={loaderGif}
              alt="loader"
              style={{height: "70px"}}
            />
          </div>)}
      </InfiniteScroll>
      <div className="modal fade" id={`decline-modal`} tabIndex="-1" role="dialog"
           aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content text-center">
            <div className="modal-header">
              <h3 className="modal-title">Deactivate</h3>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="py-3 text-center mr-auto ml-auto">
                <i className="ni ni-bell-55 ni-3x"/>
                <div>
                  <h3 className="mt-4">Are you sure you want to <span style={{color: 'red'}}>DEACTIVATE</span> this
                    Dream? </h3>
                </div>
              </div>
            </div>
            <div className="modal-footer d-flex">
              <button type="button" className="btn btn-danger mr-auto" data-dismiss="modal">Cancel</button>
              <button type="button" onClick={() => deactivateDream(dreamToDeactivate)} data-dismiss="modal"
                      className="btn btn-success">Accept It
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id={`restore-modal`} tabIndex="-1" role="dialog"
           aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content text-center">
            <div className="modal-header">
              <h3 className="modal-title">Restore</h3>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="py-3 text-center mr-auto ml-auto">
                <i className="ni ni-bell-55 ni-3x"/>
                <div>
                  <h3 className="mt-4">Are you sure you want to <span style={{color: 'red'}}>RESTORE</span> this
                    Dream? </h3>
                </div>
              </div>
            </div>
            <div className="modal-footer d-flex">
              <button type="button" className="btn btn-danger mr-auto" data-dismiss="modal">Cancel</button>
              <button type="button" onClick={() => restoreUserById(dreamToDeactivate)} data-dismiss="modal"
                      className="btn btn-success">Accept It
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" data-backdrop='false' id={`user-modal`} tabIndex="-1" role="dialog"
           aria-hidden="true">
        <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
          <div className="modal-content text-center">
            <div>
              <div className="flex flex-column my-border-gradient justify-content-around"
              >
                <Card className="card-profile shadow">
                  <Row className="justify-content-center">
                    <Col className="order-lg-2" lg="3">
                      <div className="card-profile-image">
                        <a onClick={(e) => e.preventDefault()}>
                          <img
                            style={{width: '150px', height: '150px', objectFit: 'cover'}}
                            className="rounded-circle img-thumbnail img-responsive"
                            src={user?.mainImageUrl || 'https://www.kindpng.com/picc/m/24-248253_user-profile-default-image-png-clipart-png-download.png'}
                            alt="img"/>
                        </a>
                      </div>
                    </Col>
                  </Row>
                  <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                    <div className="d-flex justify-content-between">
                      <Button
                        className="mr-4 btn-danger"
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        size="sm"
                      >
                        Deactivate
                      </Button>
                      <Button
                        className="float-right"
                        color="info"
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        size="sm"
                      >
                        Message
                      </Button>
                    </div>
                  </CardHeader>
                  <CardBody className="pt-0 pt-md-4">
                    <div className="text-center mt-md-4">
                      <h3>
                        {user?.firstName} {user?.lastName}
                      </h3>
                      <div className="h5 font-weight-500">
                        <span>{user?.email}</span>
                      </div>
                      <div className="h5 mt-4">
                        <i className="ni business_briefcase-24 mr-2"/>
                        {user?.description}
                      </div>
                      <div>
                        <i className="ni education_hat mr-2"/>
                        {user?.role}
                      </div>
                      <hr className="my-4"/>
                      {showMore ? <p>
                        {user?.description}
                      </p> : <div>
                        Some More Information
                      </div>}
                      <button className="rounded" color="info" style={{cursor: 'pointer'}}
                              onClick={() => setShowMore(prev => !prev)}>
                        {showMore ? 'Show More' : 'Show Less'}
                      </button>
                    </div>
                  </CardBody>
                </Card>
              </div>
              <button type="button" style={{width: '100%'}} data-dismiss="modal"
                      className="btn btn-success" onClick={() => donations?.length ? setIsShow(true) : null}>OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Dreams

import React, {useState} from 'react';
import {
  Button,
  Modal,
  Col,
  Row,
  Form, ModalHeader, ModalBody, FormGroup
} from "reactstrap";
import './charity.css'
import {createCharity, getCharities, uploadCharityImage, uploadCharityImages} from "../services/api";
import {setCharities, setShowLoader} from "../store/admin";
import {useDispatch, useSelector} from "react-redux";
import loader from '../assets/img/page-load-loader.gif'

const CreateCharityModal = ({isOpen, setIsOpen,page}) => {
  const dispatch = useDispatch();
  const hiddenFileInput = React.useRef(null);
  const showLoader = useSelector((state) => state.adminModule).showLoader;
  const [images, setImages] = useState([]);
  const [showedImages, setShowedImages] = useState({
    firstImage:
      "https://reactnativecode.com/wp-content/uploads/2018/02/Default_Image_Thumbnail.png",
  });


  const handleSubmit = (event) => {
    event.preventDefault();
    const {title,description, amount} = event.target.elements;
    createCharity(title.value,description.value, parseInt(amount.value, 10)).then((e) => {
      const id = e.data.id;
      dispatch(setShowLoader(true))
      if (images.length === 1) {
        uploadCharityImage(id, images[0]).then((response) => {
          getCharities(page,10).then((response) => {
            dispatch(setCharities(response?.data));
            setIsOpen(false)
            setShowedImages({
              firstImage:
                "https://reactnativecode.com/wp-content/uploads/2018/02/Default_Image_Thumbnail.png",
            })
          });
          if (!response) {
            return;
          }
        })
      } else {
        if (1 < images.length < 6) {
          uploadCharityImages(id, images).then((response) => {
            getCharities(page,10).then((response) => {
              dispatch(setCharities(response?.data));
              setIsOpen(false)
              setShowedImages({
                firstImage:
                  "https://reactnativecode.com/wp-content/uploads/2018/02/Default_Image_Thumbnail.png",
              })
            });
            if (!response) {
              return;
            }
          })
        }
      }
    });
  };

  const handleImageChange = (e) => {
    const selectedImages = [...e.target.files];
    if (!!images[0]) {
      images.map((img) => {
        return selectedImages.push(img);
      });
    }
    if (selectedImages.length <= 5) {
      setImages(selectedImages);
      const firstImage = URL.createObjectURL(selectedImages[0]);
      const otherImages = [];
      const selectedImagesClone = [...selectedImages];
      selectedImagesClone.splice(0, 1);
      selectedImagesClone?.map((img) => {
        return otherImages.push(URL.createObjectURL(img));
      });
      setShowedImages({firstImage, otherImages});
    } else {
      console.log('error')
    }
  };

  const confirmDelete = (imageIndex) => {
    const selectedImages = [...images];
    selectedImages.splice(imageIndex, 1);
    setImages(selectedImages);
    const firstImage = selectedImages[0]
      ? URL.createObjectURL(selectedImages[0])
      : "https://reactnativecode.com/wp-content/uploads/2018/02/Default_Image_Thumbnail.png";
    const otherImages = [];
    const selectedImagesClone = [...selectedImages];
    selectedImagesClone.splice(0, 1);
    selectedImagesClone?.map((img) => {
      return otherImages.push(URL.createObjectURL(img));
    });
    setShowedImages({firstImage, otherImages});
  };

  return (
    <>
      <Modal isOpen={isOpen}
             fullscreen="lg"
             size="xl"
      >
        <ModalHeader>
          <h4 className="card-title">Create Charity</h4>
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <FormGroup className="form-group align-items-center">
              <Col md="12">
                <div className="profile-img-edit" style={{margin: "0 20px 0 0"}}>
                  <img
                    className="profile-pic"
                    src={showedImages?.firstImage}
                    alt="profile-pic"
                  />
                  <div className="p-image">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      className="bi bi-plus"
                      viewBox="0 0 16 16"
                      onClick={() => hiddenFileInput.current.click()}
                    >
                      <path
                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                    </svg>

                    <input
                      style={{display: 'none'}}
                      ref={hiddenFileInput}
                      type="file"
                      accept="image/png, image/jpeg, image/jpg"
                      multiple
                      name="image"
                      id="image"
                      onChange={(e) => handleImageChange(e)}
                    />
                  </div>
                  {!!images[0] ? (
                    <button
                      type="button"
                      className="close-image"
                      aria-label="Close"
                      onClick={() => confirmDelete(0)}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  ) : null}
                </div>
                {showedImages?.otherImages?.map((img, i) => {
                  return (
                    <div
                      className="profile-img-edit"
                      key={i}
                      style={{margin: "0 20px 0 0"}}
                    >
                      <div
                        className="profile-pic"
                        style={{
                          backgroundImage: `url(${img})`,
                        }}
                      />
                      <button
                        type="button"
                        className="close-image"
                        aria-label="Close"
                        onClick={() => confirmDelete(i + 1)}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  );
                })}
              </Col>
            </FormGroup>
            <Row className="align-items-center">
              <FormGroup className="form-group col-sm-12">
                <label className="form-label">Title:</label>
                <textarea
                  as="textarea"
                  className="form-control"
                  rows={5}
                  style={{lineHeight: "22px"}}
                  id="title"
                  placeholder="Title"
                  required
                />
              </FormGroup>


             <FormGroup className="form-group col-sm-12">
              <label className="form-label">Description:</label>
              <textarea
                  as="textarea"
                  className="form-control"
                  rows={5}
                  style={{lineHeight: "22px"}}
                  id="description"
                  placeholder="Description"
                  required
              />
            </FormGroup>
              <FormGroup className="form-group col-sm-6">
                <label
                  htmlFor="firstName"
                  className="form-label"
                >
                  Amount:
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="amount"
                  placeholder="Amount"
                  required
                />
              </FormGroup>
            </Row>
            <Button
              disabled={!images[0]}
              type="submit"
              color="primary"
            >
              Submit
            </Button>
            <Button
              type="reset"
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </Button>
            {showLoader && (
              <img
                src={loader}
                alt="loader"
                style={{height: "39.2px"}}
              />
            )}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );

}

export default CreateCharityModal;
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux"
import {
  Row,
  Col,
  Form,
  Button,
  Modal, ModalBody, ModalHeader, FormGroup
} from "reactstrap";
import './charity.css'
import loader from '../assets/img/page-load-loader.gif'
import {
  deleteCharityImage,
  getCharities, getCharityById,
  updateCharity,
  uploadCharityImage,
  uploadCharityImages
} from "../services/api";
import {setCharities, setCharity, setShowLoader} from "../store/admin";

const EditCharity = ({id, isShow, setIsShow}) => {
  const hiddenFileInput = React.useRef(null);
  const dispatch = useDispatch();
  const [imageId, setImageId] = useState("");
  const [modalProps, setModalProps] = useState(false);
  const showLoader = useSelector((state) => state.adminModule).showLoader;
  const charity = useSelector((state) => state.adminModule).charity;
  const [charityData, setCharityData] = useState(charity);
  // const errorMessage = useSelector((state) => state.usersModule).errorMessage;


  useEffect(() => {
    setCharityData(charity);
  }, [charity])

  const images = () => {
    let image = [];
    charity?.images?.forEach((img) => {
      if (img.isMain !== true) image.push(img);
    })
    return image;
  }

  const ModalRender = () => {
    return (
      <Modal
        backdropClassName={"bg-transparent"}
        centered
        backdrop={true}
        isOpen={modalProps}
      >
        <div>
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              Confirmation
            </h5>
          </div>
          <div className="modal-body">
            Are you sure that you want to delete the photo?
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={() => confirmDelete()}
            >
              Yes
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => setModalProps(false)}
            >
              No
            </button>
          </div>
        </div>
      </Modal>
    );
  };
  const handleEdit = (event) => {
    const target = event.target;
    const charityDataClone = {...charityData};
    charityDataClone[target.id] = target.value;
    setCharityData(charityDataClone);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(setShowLoader(true));
    const {title, amount} = event.target.elements;
    updateCharity(charity.id, title.value, parseInt(amount.value, 10)).then(() => {
      getCharities(1).then((response) => {
        dispatch(setCharities(response.data));
        setIsShow(false);
      });

    });
  };

  const handleImageChange = (e) => {
    // dispatch(setErrorMessage(""));
    const images = [...e.target.files];
    if (images.length === 1) {
      uploadCharityImage(charity.id, images[0]).then(() => {
        getCharityById(id).then((response) => {
          dispatch(setCharity(response.data));
        });
      });
    } else {
      if (1 < images.length < 6) {
        uploadCharityImages(charity.id, images).then(() => {
          getCharityById(id).then((response) => {
            dispatch(setCharity(response.data));
          });
        });
      }
    }
  };

  const confirmDelete = () => {
    deleteCharityImage(charity.id, imageId).then(() => {
      getCharityById(id).then((response) => {
        dispatch(setCharity(response.data));
        setModalProps(false);
      });
    });
  };

  return (
    <>
      <Modal
        isOpen={isShow}
        fullscreen="lg"
        size="xl">
        <ModalHeader className="d-flex justify-content-between">
          <div className="header-title">
            <h4 className="card-title">Edit Charity</h4>
          </div>
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <FormGroup className="form-group align-items-center">
              <Col sm={12}>
                <div className="profile-img-edit" style={{margin: "0 20px 0 0"}}>
                  <img
                    className="profile-pic"
                    src={charityData?.images?.find((image) => image.isMain === true)?.url || "https://reactnativecode.com/wp-content/uploads/2018/02/Default_Image_Thumbnail.png"}
                    alt="profile-pic"
                  />
                  <div className="p-image">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      className="bi bi-plus"
                      viewBox="0 0 16 16"
                      onClick={() => hiddenFileInput.current.click()}
                    >
                      <path
                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                    </svg>

                    <input
                      style={{display: 'none'}}
                      ref={hiddenFileInput}
                      type="file"
                      accept="image/png, image/jpeg, image/jpg"
                      multiple
                      name="image"
                      id="image"
                      onChange={handleImageChange}
                    />
                  </div>
                  {!!charityData?.images?.find((image) => image.isMain === true)?.url ? (
                    <button
                      type="button"
                      className="close-image"
                      aria-label="Close"
                      onClick={() => {
                        setModalProps(true);
                        setImageId(charityData?.images?.find((image) => image.isMain === true)?.id);
                      }}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  ) : null}
                </div>

                {images()?.map((img, i) => {
                  return (
                    <div
                      className="profile-img-edit"
                      key={i}
                      style={{margin: "0 20px 0 0"}}
                    >
                      <div
                        className="profile-pic"
                        style={{
                          backgroundImage: `url(${img.url})`,
                        }}
                      />
                      <button
                        type="button"
                        className="close-image"
                        aria-label="Close"
                        onClick={() => {
                          setModalProps(true);
                          setImageId(img.id);
                        }}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  );
                })}
                {ModalRender()}
              </Col>
              {/*<p className="text-danger">{errorMessage}</p>*/}
            </FormGroup>
            <Row className="align-items-center">
              <FormGroup className="form-group col-sm-12">
                <label className="form-label">Title:</label>
                <textarea
                  className="form-control"
                  rows={5}
                  style={{lineHeight: "22px"}}
                  id="title"
                  placeholder="Title"
                  required
                  value={charityData?.title || ''}
                  onChange={handleEdit}
                />
              </FormGroup>
              <FormGroup className="form-group col-sm-6">
                <label
                  htmlFor="firstName"
                  className="form-label"
                >
                  Amount:
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="amount"
                  placeholder="Amount"
                  required
                  value={charityData?.amount || 0}
                  onChange={handleEdit}
                />
              </FormGroup>
            </Row>
            <Button
              type="reset"
              className="btn bg-soft-danger me-2"
              onClick={() => setIsShow(false)}
            >
              Cancel
            </Button>
            <Button
              disabled={!charity?.images?.length}
              type="submit"
              className="btn btn-primary me-2"
            >
              Submit
            </Button>
            {showLoader && (
              <img
                src={loader}
                alt="loader"
                style={{height: "39.2px"}}
              />
            )}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default EditCharity;

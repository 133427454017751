import React, {useEffect, useState} from 'react'
import {getFeedbacks} from '../../services/api'
import {setInputValue, setFeedbacks, setAddFeedbacks} from "../../store/admin";
import InfiniteScroll from 'react-infinite-scroller-improve';
import loaderGif from '../../assets/images/page-load-loader.gif'
import {
    Media,
    Table,
} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";

let page2d = 1;
let loader = false;
const Feedbacks = () => {
    const dispatch = useDispatch();
    const feedbacks = useSelector((state) => state.adminModule).feedbacks;
    const [hasNextPage, setHasNextPage] = useState(true);

    useEffect(() => {
        getFeedbacks(1).then((res) => {
            dispatch(setFeedbacks(res?.data));
            setHasNextPage(res.data?.meta?.hasNextPage)
            dispatch(setInputValue(''));
        })
    }, [])

    useEffect(() => () => {
        page2d = 1
        dispatch(setFeedbacks({}))
        setHasNextPage(false)
    }, [])

    const loadMore = () => {
        if (!!feedbacks?.results?.length) {
                if (!loader) {
                    page2d += 1;
                    loader = true;
                    getFeedbacks(page2d).then((response) => {
                        dispatch(setAddFeedbacks(response?.data))
                        loader = false;
                        setHasNextPage(response?.data?.meta?.hasNextPage)
                    })
                }
        }
    }
    const apiUsers = feedbacks?.results?.map((el) => {
        return (
            <tr key={el?.id}
                style={{backgroundColor: 'white'}}

            >
                <th scope="row">
                    <Media className="align-items-center">
                        <div className="avatar-group">
                            <img
                                alt='img'
                                className="avatar avatar-lg"
                                style={{objectFit: "cover"}}
                                src={el.user?.mainImageUrl || 'https://www.kindpng.com/picc/m/24-248253_user-profile-default-image-png-clipart-png-download.png'}
                            />
                        </div>
                        <Media>
              <span className="mb-0 ml-2 text-sm">
                {el?.user?.firstName} {el?.user?.lastName}
              </span>
                        </Media>
                    </Media>
                </th>
                <td>
                    <div className="d-flex align-items-center">
                        <span className="mr-2 text-wrap">{el?.message}</span>
                    </div>
                </td>
                <td>
                    <div className="d-flex align-items-center">
                        <span className="mr-2">{el.user?.role}</span>
                    </div>
                </td>
                <td>
                    <span>{moment(el?.updatedAt).format('DD MMMM YYYY HH:mm')}</span>
                </td>
            </tr>
        )
    })
    return (
        <>
            <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
            </div>
            <InfiniteScroll
                loadMore={() => loadMore()}
                hasMore={hasNextPage}
                useWindow={true}
            >
                <Table className="align-items-center table-flush" responsive>

                    <thead className="thead-light">
                    <tr>
                        <th scope="col">User Name:</th>
                        <th scope="col">Message:</th>
                        <th scope="col">Role:</th>
                        <th scope="col">CreateAt:</th>
                        <th scope="col">{`Total users(${feedbacks?.meta?.itemCount || 0})`}</th>
                    </tr>
                    </thead>

                    <tbody>
                    {apiUsers}
                    </tbody>

                </Table>
                {(loader &&
                    <div className="d-flex justify-content-center w-100" style={{width: '100%'}}>
                        <img
                            src={loaderGif}
                            alt="loader"
                            style={{height: "70px"}}
                        />
                    </div>)}
            </InfiniteScroll>
        </>
    )
}

export default Feedbacks
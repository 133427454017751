import {createSlice} from "@reduxjs/toolkit";

const adminSlice = createSlice({
    name: 'admin',
    initialState: {
      isAuth: false,
      name: 'Hello',
      inputValue: '',
      users: [],
      feedbacks:{},
      filteredUsers: [],
      dreams: [],
      filteredDreams: [],
      donations: [],
      filteredDonations: [],
      charities: [],
      filteredCharities: [],
      wingDonations: [],
      filteredWingDonations: [],
      charity: {},
      showLoader: false,
      modalDonations: {},
      payouts: {}
    },
    reducers: {
      setFeedbacks: (state, action) => {
        state.feedbacks = action.payload
      },
      setAddFeedbacks: (state, action) => {
        state.feedbacks = {
          meta: action.payload?.meta,
          results: [...state.feedbacks?.results?.map(el => ({...el})), ...action.payload?.results?.map(el => ({...el}))]
        }
      },
      setShowLoader: (state, action) => {
        state.showLoader = action.payload
      },
      setPayouts: (state, action) => {
        state.payouts = action.payload
      },
      setAddPayouts: (state, action) => {
        state.payouts = {
          meta: action.payload?.meta,
          results: [...state.payouts?.results?.map(el => ({...el})), ...action.payload?.results?.map(el => ({...el}))]
        }
      },
      setPatchPayments: (state, action) => {
        const newPayments = JSON.parse(JSON.stringify(state.payouts));
        newPayments.results[newPayments?.results?.findIndex((el) => el.id === action.payload.id)].status = action.payload.status;
        state.payouts = newPayments;
      },
      setIsAuth: (state, action) => {
        state.isAuth = action.payload
      },
      setUsers: (state, action) => {
        state.users = action.payload
        state.filteredUsers = action.payload
      },
      setAddUsers: (state, action) => {
        state.users = {
          meta: action.payload?.meta,
          results: [...state.users?.results?.map(el => ({...el})), ...action.payload?.results?.map(el => ({...el}))]
        }
      },
      setAddModalDonations: (state, action) => {
        state.modalDonations = {
          meta: {...action.payload?.meta},
          results: [...state.modalDonations?.results?.map(el => ({...el})), ...action.payload?.results?.map(el => ({...el}))]
        }
      },
      setModalDonations: (state, action) => {
        state.modalDonations = action.payload
      },
      setFilteredUsers: (state, action) => {
        state.filteredUsers = action.payload
      },
      setDreams: (state, action) => {
        state.dreams = action.payload
        state.filteredDreams = action.payload
      },
      setAddDreams: (state, action) => {
        state.dreams = {
          meta: action.payload?.meta,
          results: [...state.dreams?.results?.map(el => ({...el})), ...action.payload?.results?.map(el => ({...el}))]
        }
        state.filteredDreams = action.payload
      },
      setFilteredDreams: (state, action) => {
        state.filteredDreams = action.payload
      },
      setDonations: (state, action) => {
        state.donations = action.payload
        state.filteredDonations = action.payload
      },
      setAddDonations:(state,action)=>{
        state.donations = {
          meta: action.payload?.meta,
          results: [...state.donations?.results?.map(el => ({...el})), ...action.payload?.results?.map(el => ({...el}))]
        }
      },
      setFilteredDonations: (state, action) => {
        state.filteredDonations = action.payload
      },
      setInputValue: (state, action) => {
        state.inputValue = action.payload
      },
      setCharities: (state, action) => {
        state.charities = action.payload
        state.filteredCharities = action.payload
      },
      setAddCharities:(state,action)=>{
        state.charities = {
          meta: action.payload?.meta,
          results: [...state.donations?.results?.map(el => ({...el})), ...action.payload?.results?.map(el => ({...el}))]
        }
      },
      setAddWingDonations:(state,action)=>{
        state.wingDonations = {
          meta: action.payload?.meta,
          results: [...state.wingDonations?.results?.map(el => ({...el})), ...action.payload?.results?.map(el => ({...el}))]
        }
      },

      setFilteredCharities: (state, action) => {
        state.filteredCharities = action.payload
      },
      setCharity: (state, action) => {
        state.charity = action.payload
      },
      setWingDonations: (state, action) => {
        state.wingDonations = action.payload
        state.filteredWingDonations = action.payload
      },
      setFilteredWingDonations: (state, action) => {
        state.filteredWingDonations = action.payload
      }
    }
  },
)

export const {
  setIsAuth,
  setShowLoader,
  setUsers,
  setDreams,
  setDonations,
  setInputValue,
  setCharities,
  setAddCharities,
  setAddWingDonations,
  setCharity,
  setAddDonations,
  setWingDonations,
  setAddUsers,
  setAddDreams,
  setAddModalDonations,
  setModalDonations,
  setAddPayouts,
  setPayouts,
  setPatchPayments,
  setFeedbacks,
  setAddFeedbacks
} = adminSlice.actions;
export default adminSlice.reducer;
import React from "react";
import App from '../src/App'
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import {Provider} from "react-redux";
import {store} from './store'
import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import 'bootstrap'



ReactDOM.render(
    <Provider store={store}>
  <BrowserRouter>
       <App/>
  </BrowserRouter>
    </Provider>
    ,

  document.getElementById("root")
);

export const AdminMessages = {
  "DREAMER" : [
    "Begin by donating $1 for each dream and accumulate half of your dream amount to turn your dream into reality!",
    "Please select five beautiful images that represent your dream to expand the reach of your campaign!",
    "Please upload five attractive pictures to your profile page to enhance transparency and extend the reach of your campaign!",
    "Please reformulate your dream description to have a bigger impact on your campaign!",
    "Share your dream with your friends on social media for a greater impact and faster dream fulfillment.",
    "Share wing donation campaigns on social media for a bigger impact!",
    "By sharing with your friends and family, you’re helping us build a strong and compassionate community where every dream matters!",
    "Consistency is the key to realizing your dreams – regular donations create a wave of impact, propelling you and others forward.",
    "If you’ve mistakenly entered the wrong dream amount, please contact us at info@dreamerz.net or leave us feedback, and we’ll promptly resolve the issue."
  ],
  "ANGEL":[
    "Kindly add five attractive images to your profile page to enhance transparency, allowing the community to get to know their generous Dream Angels!",
    "Begin by donating $1 for each dream and collect fulfilled dreams for your future aspirations or further donations, all while receiving gratitude and appreciation from the community!",
    "Share dreams you love on social media to help dreamers reach their goal!",
    "Share wing donation campaigns on social media for a bigger impact!",
    "By sharing with your friends and family, you’re helping us build a strong and compassionate community where every dream matters!",
    "Consistency is the key to realizing your dreams – regular donations create a wave of impact, propelling you and others forward."
  ]
}

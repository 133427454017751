import React, {useEffect} from "react";
import {Redirect, Route, Switch, useHistory} from "react-router-dom";
import AdminLayout from "./layouts/Admin";
import AuthLayout from "./layouts/Auth";

function App() {
  const navigate = useHistory()

  useEffect(() => {
    if (localStorage.getItem('adminAccessToken') && navigate.location.pathname === '/auth/login') {
      navigate.push('/admin/users');
    }
  }, [])

  

  return (
    <div>
      <Switch>
        <Route path="/auth/login" render={(props) => <AuthLayout {...props} />}/>
        <Route path="/admin/" render={(props) => <AdminLayout {...props} />}/>
        <Redirect from="*" to="/auth/login"/>
      </Switch>
    </div>
  );
}

export default App;
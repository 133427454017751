import React, {useState} from 'react';
import {Link, useHistory} from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media, Button,
} from "reactstrap";
import {setInputValue, setUsers} from "../../store/admin";
import {useDispatch, useSelector} from "react-redux";
import {exportUsers, getSearchedUsers, getUsers} from "../../services/api";
import moment from "moment";

const AdminNavbar = (props) => {
  const navigate = useHistory()
  const inputValue = useSelector((state) => state.adminModule).inputValue;
  const dispatch = useDispatch();

  const [timer, setTimer] = useState( null);
  const [searchByEmail, setSearchByEmail] = useState( false);

  const toExportUsers = (e) => {
    e.preventDefault();
    const { startDate, endDate } = e.target.elements;
    exportUsers(startDate.value, endDate.value).then((response)=> {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      const fileName = `Report ${moment(new Date()).format("DD MMM YY")}.csv`;
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      link.remove()
    })
  }

  const isValidEmail=(email)=> {
    return /\S+@\S+\.\S+/.test(email);
  }
  const handleChange = (e) => {
    dispatch(setInputValue(e.target.value));
    const value = e.target.value
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(
        setTimeout(() => {
          if (value) {
           searchByEmail  ?
           isValidEmail(value) && getSearchedUsers("email", value)
                .then((res) => {
                dispatch(setUsers({
                meta:res?.data?.meta,
                results:res?.data}));
            }) :
               getSearchedUsers( "name", value)
                   .then((res) => {
                     dispatch(setUsers({
                       meta:res?.data?.meta,
                       results:res?.data}));
                   })
          } else {
            getUsers(1).then((res) => {
              dispatch(setUsers(res?.data));
            })
          }
        }, 1000)
    );
  }
  const emptyInput = () => {
    dispatch(setInputValue(""));
    getUsers(1).then((res) => {
      dispatch(setUsers(res?.data));
    })
  }
  const LogOut = () => {
    localStorage.removeItem('adminAccessToken');
    localStorage.removeItem('adminRefreshToken');
    navigate.push('/auth/login');
  }

  return (
    <>
      <Navbar className="navbar-top navbar-dark d-flex flex-column" expand="md" id="navbar-main">
        <Container className="mb-4">
          <div className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block">
            {props.brandText}
          </div>
          <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
            <FormGroup className="mb-0">
              <InputGroup className={
                !searchByEmail ||
                isValidEmail(inputValue) ||
                !inputValue ?
                    "input-group-alternative" :
                    "input-group-alternative border-warning"}>
                <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <InputGroupText>
                      {searchByEmail ?
                          <i className="fa fa-envelope" aria-hidden="true"/>:
                          <i className="fa fa-user" aria-hidden="true"/>
                          }
                    </InputGroupText>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Search by</h6>
                  </DropdownItem>
                  <DropdownItem onClick={()=>setSearchByEmail(false)}>
                    <i className="fa fa-user" aria-hidden="true"/>
                    <span>Name</span>
                  </DropdownItem>
                  <DropdownItem onClick={()=>setSearchByEmail(true)}>
                    <i className="fa fa-envelope" aria-hidden="true"/>
                    <span>Email</span>
                  </DropdownItem>
                </DropdownMenu>
                </UncontrolledDropdown>
                <Input
                  placeholder={searchByEmail ?
                      "Search user by email" :
                      "Search user by name"
                }
                  type={searchByEmail ? "email" : "text"}
                  value={inputValue}
                  onChange={e=>handleChange(e)}
                />
                <InputGroupAddon addonType="prepend">
                  <InputGroupText className="mr-2"
                                  onClick={emptyInput}
                                  style={{cursor:"pointer"}}>
                    <i className="fa fa-times" aria-hidden="true"/>
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </Form>
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      src={
                        require("../../assets/img/theme/team-4-800x800.jpg")
                          .default
                      }
                    />
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                     Admin
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-single-02"/>
                  <span>My profile</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-settings-gear-65"/>
                  <span>Settings</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-calendar-grid-58"/>
                  <span>Activity</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-support-16"/>
                  <span>Support</span>
                </DropdownItem>
                <DropdownItem divider/>
                <DropdownItem href="#pablo" onClick={() => LogOut()}>
                  <i className="ni ni-user-run"/>
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
        <Form
          className=" navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto"
          onSubmit={toExportUsers}
        >
          <div className="d-flex flex-column">
          <Input
            id="startDate"
            name="startDate"
            placeholder="startDate"
            type="date"
            required
            className="mb-2 bg-transparent"
          />
          <Input
            id="endDate"
            name="endDate"
            placeholder="endDate"
            type="date"
            required
            className="mb-2 bg-transparent"
          />
          <Button type='submit'>Export Users</Button>
          </div>
        </Form>
      </Navbar>
    </>
  );
};

export default AdminNavbar;

import React, {useState, useEffect} from "react";
import InfiniteScroll from 'react-infinite-scroller-improve';
import loaderGif from '../../assets/images/page-load-loader.gif'
import {getPayouts, patchPayments} from '../../services/api'
import {setInputValue, setPayouts, setAddPayouts,setPatchPayments} from "../../store/admin";
import {useDispatch, useSelector} from "react-redux";
import {Button} from "reactstrap";
import moment from "moment";
import {
  Media,
  Table,
} from "reactstrap";

let page2d = 1;
let loader = false;

const Payouts = () => {
  const dispatch = useDispatch();
  const [hasNextPage, setHasNextPage] = useState(true);
  const [selectValue, setSelectValue] = useState('');
  const [textareaValue, setTextareaValue] = useState('');
  const payouts = useSelector((state) => state.adminModule).payouts;

  useEffect(() => {
    getPayouts(1).then((res) => {
      dispatch(setPayouts(res?.data));
      setHasNextPage(res.data?.meta?.hasNextPage)
      dispatch(setInputValue(''));
    })
  }, [])

  useEffect(() => () => {
    page2d = 1
    dispatch(setPayouts({}))
    setHasNextPage(false)
  }, [])

  const loadMore = () => {
    if (!!payouts?.results?.length) {
      if (!loader) {
        page2d += 1;
        loader = true;
        getPayouts(page2d).then((response) => {
          dispatch(setAddPayouts(response?.data))
          loader = false;
          setHasNextPage(response?.data?.meta?.hasNextPage)

        })
      }
    }
  }
  const handleChange = (e) => {
    setSelectValue(e.target.value);
  }
  const patchPayment = (id) => {
    patchPayments(id, {status: selectValue, description: textareaValue}).then((res) => {
      if (res) {
        dispatch(setPatchPayments(res?.data))
        setSelectValue('');
        setTextareaValue('')
      }
    })
    // setInputValue(e.target.value);
  }
  const apiUsers = payouts?.results?.map((el) => {
    return (
      <tr key={el?.id}>
        <th scope="row">
          <Media className="align-items-center">
            <div className="avatar-group">
              <img
                alt='img'
                className="avatar avatar-lg"
                style={{objectFit: "cover"}}
                src={el?.user?.mainImageUrl || 'https://www.kindpng.com/picc/m/24-248253_user-profile-default-image-png-clipart-png-download.png'}
              />
            </div>
            <Media>
              <span className="ml-1 text-wrap">
                {el?.user?.firstName} {el?.user?.lastName}
              </span>
            </Media>
          </Media>
        </th>
        <td>
          <span>
            {el?.phoneNumber}
          </span>
        </td>
        <td>
          <span>
            {el?.amount}
          </span>
        </td>
        <td>
          <div className="d-flex align-items-center flex-column justify-content-center">
            <span className="mr-2">{el?.status}</span>
            <Button color="success"
                    data-toggle="modal"
                    data-target={`#users-modal${el.id}`}
                    type="button"
                    onClick={() => setSelectValue(el?.status)}
            >
              Change status
            </Button>
            <div className="modal fade" id={`users-modal${el.id}`} tabIndex="-1" role="dialog"
                 aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content text-center p-3">
                  <div className="modal-header">
                    <h3 className="modal-title">Change status</h3>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="p-5 text-center mr-auto ml-auto d-flex flex-column">
                      <select name="status" className="form-control mb-3" value={selectValue} onChange={handleChange}>
                        <option value="PAID">PAID</option>
                        <option value="PENDING">PENDING</option>
                        <option value="IN_TRANSIT">IN_TRANSIT</option>
                        <option value="CANCELED">CANCELED</option>
                        <option value="FAILED">FAILED</option>
                      </select>
                      <textarea type="text"
                                placeholder='Message'
                                className="form-control"
                                value={textareaValue}
                                onChange={(e) => setTextareaValue(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="modal-footer d-flex">
                    <button type="button" className="btn btn-danger mr-auto" data-dismiss="modal">Cancel</button>
                    <button type="button" data-dismiss="modal"
                            className="btn btn-success"
                            onClick={() => patchPayment(el.id)}
                    >
                      Modify
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </td>
        <td>
          <span className='text-wrap'>{el?.country}</span>
        </td>
        <td>
          <span className='text-wrap'>{el?.city}</span>
        </td>
        <td>
          <span className='text-wrap'>{el?.state}</span>
        </td>
        <td style={{maxWidth: '150px'}}>
          <span className='text-wrap'>{el?.address}</span>
        </td>
        <td style={{maxWidth: '150px'}}>
          <span className='text-wrap'>{el?.address2}</span>
        </td>
        <td>
          <span className='text-wrap'>{el?.zipCode}</span>
        </td>
        <td>
          <span className='text-wrap'>{el?.swift}</span>
        </td>
        <td>
          <span className='text-wrap'>{el?.iban}</span>
        </td>
        <td>
          <span className='text-wrap'>{moment(el?.updatedAt).format('DD MMMM YYYY HH:mm')}</span>
        </td>
        {/*<td>*/}
        {/*  <Button color="success" data-toggle="modal" data-target={`#users-modal${el.id}`}*/}
        {/*          type="button">Change status</Button>*/}
        {/*  <div className="modal fade" id={`users-modal${el.id}`} tabIndex="-1" role="dialog"*/}
        {/*       aria-hidden="true">*/}
        {/*    <div className="modal-dialog modal-dialog-centered" role="document">*/}
        {/*      <div className="modal-content text-center p-3">*/}
        {/*        <div className="modal-header">*/}
        {/*          <h3 className="modal-title">Change status</h3>*/}
        {/*          <button type="button" className="close" data-dismiss="modal" aria-label="Close">*/}
        {/*            <span aria-hidden="true">&times;</span>*/}
        {/*          </button>*/}
        {/*        </div>*/}
        {/*        <div className="modal-body">*/}
        {/*          <div className="p-5 text-center mr-auto ml-auto d-flex flex-column">*/}
        {/*            <input type="text" placeholder='status' className='mb-2'/>*/}
        {/*            <input type="text" placeholder='description'/>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*        <div className="modal-footer d-flex">*/}
        {/*          <button type="button" className="btn btn-danger mr-auto" data-dismiss="modal">Cancel</button>*/}
        {/*          <button type="button" data-dismiss="modal"*/}
        {/*                  className="btn btn-success">Modify*/}
        {/*          </button>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</td>*/}
      </tr>
    )
  })

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
      </div>
      <InfiniteScroll
        loadMore={() => loadMore()}
        hasMore={hasNextPage}
        useWindow={true}
      >
        <Table className="align-items-center table-flush" responsive>

          <thead className="thead-light">
          <tr>
            <th scope="col">Users{`(${payouts?.meta?.itemCount || 0})`}</th>
            <th scope="col">Phone number</th>
            <th scope="col">Amount</th>
            <th scope="col">Status:</th>
            <th scope="col">Country</th>
            <th scope="col">City</th>
            <th scope="col">State</th>
            <th scope="col">Address</th>
            <th scope="col">Address2</th>
            <th scope="col">ZipCode</th>
            <th scope="col">Swift</th>
            <th scope="col">Iban</th>
            <th scope="col">Create at</th>
            {/*<th scope="col">Change Status</th>*/}
          </tr>
          </thead>

          <tbody>
          {apiUsers}
          </tbody>

        </Table>
        {(loader &&
          <div className="d-flex justify-content-center w-100" style={{width: '100%'}}>
            <img
              src={loaderGif}
              alt="loader"
              style={{height: "70px"}}
            />
          </div>)}
      </InfiniteScroll>
    </>
  )
}
export default Payouts
import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {Media, Table, Progress} from "reactstrap";
import {getDonations} from "../../services/api";
import {setDonations, setInputValue,setAddDonations} from "../../store/admin";
import {useDispatch, useSelector} from "react-redux";
import InfiniteScroll from "react-infinite-scroller-improve";
import loaderGif from '../../assets/images/page-load-loader.gif'
import moment from "moment";
import "../index.css"
let page2d = 1;
let loader = false;

const Donations = () => {
  const donations = useSelector((state) => state.adminModule).donations
  const dispatch = useDispatch()
    const [hasMore,setHasNextPage] = useState(donations?.meta?.hasNextPage)

  useEffect(() => {
    getDonations(1,10).then((response) => {
        dispatch(setDonations(response?.data));
        setHasNextPage(response?.data?.meta?.hasNextPage)
         dispatch(setInputValue(''));
    })
  }, [])


    const loadMore = () => {
        if (!!donations?.results?.length) {
            if (!loader) {
                page2d += 1;
                loader = true;
                getDonations(page2d, 10).then((response) => {
                    dispatch(setAddDonations(response?.data))
                    setHasNextPage(response?.data?.meta?.hasNextPage)
                    loader = false;
                })
            }
        }
    }

    const apiDreams = donations?.results?.map((el,index) => {
    if (el.dream?.title && el.user?.firstName) {
      return (
        <tr key={`${index}-${el?.id}`}>
          <th scope="row">
            <Media className="align-items-center">
              <div className="avatar-group">
                <img
                  alt='img'
                  className="avatar avatar-lg"
                  style={{objectFit: "cover"}}
                  src={el?.dream?.images?.find((image) => image.isMain === true)?.avatarUrl || 'https://www.kindpng.com/picc/m/24-248253_user-profile-default-image-png-clipart-png-download.png'}
                />
              </div>
            </Media>
          </th>
          <td>
            <div className='text-wrap my-span'>
              <span>{el.dream?.title} </span>
            </div>
          </td>
          <td>
            <span>
              {el?.amount}
            </span>
          </td>
          <td>
            <div style={{cursor: 'pointer'}} className="d-flex align-items-center">
              <div>
                <Progress
                  min={0}
                  max={el?.dream?.amount}
                  value={el?.dream?.amountReceived}
                  animated={true}
                  barClassName="bg-danger"
                />
              </div>
            </div>
          </td>
          <td>
            <div className="d-flex align-items-center">
              <Link to="#"><span className="mr-2">{el.user?.firstName} {el.user?.lastName}</span></Link>
            </div>
          </td>
          <td>
              <span>{moment(el?.createdAt).format('DD MMMM YYYY HH:mm')}</span>
          </td>
          <td>
              <span>{moment(el?.updatedAt).format('DD MMMM YYYY HH:mm')}</span>

          </td>
        </tr>
      )
    }
  })
  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
      </div>
      <InfiniteScroll
          loadMore={()=>loadMore()}
          hasMore={hasMore}
          useWindow={true}
      >
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
        <tr>
          <th scope="col">Total({`${donations?.meta?.itemCount || 0}`})</th>
          <th scope="col">Title:</th>
          <th scope="col">Amount:</th>
          <th scope="col">Donations Received</th>
          <th scope="col">It's a User's dream id:</th>
          <th scope="col">CreateAt:</th>
          <th scope="col">UpdateAt:</th>
        </tr>
        </thead>
        <tbody>

            {apiDreams}


        </tbody>

      </Table>
      </InfiniteScroll>
        {(loader &&
            <div  className="d-flex justify-content-center w-100" style={{width:'100%'}}>
                <img
                    src={loaderGif}
                    alt="loader"
                    style={{height: "70px"}}
                />
            </div>)}
    </>
  )
}

export default Donations;

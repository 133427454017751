import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux"
import {
    Row,
    Form,
    Button,
    Modal, ModalBody, ModalHeader, FormGroup, Label, Input
} from "reactstrap";
import './charity.css'
import loader from '../assets/img/page-load-loader.gif'
import {postMessage} from "../services/api";
import {setShowLoader} from "../store/admin";
import {AdminMessages} from "./consts";

const AdminMessageSenderModal = ({user, isShow, setIsShow}) => {
    const dispatch = useDispatch();
    const showLoader = useSelector((state) => state.adminModule).showLoader;
    const [messageValue, setMessageValue] = useState("")

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(setShowLoader(true));
        postMessage(user?.id, messageValue).then(() => {
                setIsShow(false);
        });
    };

    return (
        <>
            <Modal
                isOpen={isShow}
                centered
                size="md">
                <ModalHeader className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                        <h4 className="m-0">Send a message to {user?.firstName} </h4>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <Form
                        onSubmit={handleSubmit}
                    >
                        <Row className="align-items-center flex-column">
                            <FormGroup className="form-group col-sm-12">
                                <Label for="exampleSelect">
                                    Select
                                </Label>
                                <Input
                                  id="exampleSelect"
                                  name="select"
                                  type="select"
                                  onChange={(e) =>setMessageValue(e.target.value)}
                                >
                                    {AdminMessages[user?.role]?.map((el, index)=>
                                      <option key={index} value={el}>
                                          {el}
                                      </option>
                                    )}
                                </Input>
                            </FormGroup>
                            <FormGroup className="form-group col-sm-12">
                                <textarea
                                    className="form-control"
                                    rows={5}
                                    style={{lineHeight: "22px"}}
                                    id="message"
                                    placeholder="Write a message..."
                                    required
                                    value={messageValue}
                                    onChange={(e)=>setMessageValue(e.target.value)}
                                />
                            </FormGroup>
                        </Row>
                        <Button
                            type="reset"
                            className="btn bg-soft-danger me-2"
                            onClick={() => setIsShow(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            className="btn btn-primary me-2"
                        >
                            Submit
                        </Button>
                        {showLoader && (
                            <img
                                src={loader}
                                alt="loader"
                                style={{height: "39.2px"}}
                            />
                        )}
                    </Form>
                </ModalBody>
            </Modal>
        </>
    );
};

export default AdminMessageSenderModal;

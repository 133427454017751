import Users from "./views/examples/Users";
import Login from "views/examples/Login.js";
import Tables from "views/examples/Tables.js";
import Dreams from "./views/examples/Dreams";
import Donations from "./views/examples/Donations";
import Charities from "./views/examples/Charities";
import WingsDonations from "./views/examples/WingsDonations";
import Payouts from './views/examples/Payouts'
import Feedbacks from "./views/examples/Feedbacks";


let routes = [
  {
    path: "/users",
    name: "Users",
    icon: "ni ni-single-02 text-yellow",
    component: Users,
    layout: "/admin",
  },
  {
    path: "/dreams",
    name: "Dreams",
    icon: "ni ni-planet text-blue",
    component: Dreams,
    layout: "/admin",
  },
  {
    path: "/wing-dreams",
    name: "Wings Dreams",
    icon: "ni ni-planet text-blue",
    component: Charities,
    layout: "/admin",
  },
  {
    path: "/donations",
    name: "Donations",
    icon: "ni ni-planet text-blue",
    component: Donations,
    layout: "/admin",
  },
  {
    path: "/wing-donations",
    name: "Wing Donations",
    icon: "ni ni-planet text-blue",
    component: WingsDonations,
    layout: "/admin",
  },
  {
    path: "/feedbacks",
    name: "Feedbacks",
    icon: "ni ni-single-02 text-yellow",
    component: Feedbacks,
    layout: "/admin",
  },
  {
    path: "/payouts",
    name: "Payments",
    icon: "ni ni-planet text-blue",
    component: Payouts,
    layout: "/admin",
  },
  // {
  //   path: "/dream-request",
  //   name: "Dream Requests",
  //   icon: "ni ni-planet text-blue",
  //   component: DreamRequests,
  //   layout: "/admin",
  // },

  {
    path: "/tables",
    name: "Tables",
    icon: "ni ni-bullet-list-67 text-red",
    component: Tables,
    layout: "/admin",
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
  },
];
export default routes;

import React, {useEffect, useState} from 'react'
import {Button} from "reactstrap";
import {deleteUser, getUsers, restoreUser} from '../../services/api'
import {setInputValue, setUsers, setAddUsers} from "../../store/admin";
import InfiniteScroll from 'react-infinite-scroller-improve';
import loaderGif from '../../assets/images/page-load-loader.gif'
import {
  Media,
  Table,
} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import AdminMessageSenderModal from "../../components/AdminMessageSenderModal";

let page2d = 1;
let loader = false;
const Users = () => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.adminModule).users;
  const [hasNextPage, setHasNextPage] = useState(true);
  const [isShow, setIsShow] = useState(false);
  const [user, setUser] = useState({});

  const deactivateUser = (id) => {
    deleteUser(id).then(() => {
      getUsers(1).then((res) => {
        dispatch(setUsers(res?.data));
        setHasNextPage(res?.data?.meta?.hasNextPage)
        page2d = 1;
        dispatch(setInputValue(''));
      })
    })
  }
  const restoreUserById = (id) => {
    restoreUser(id).then(() => {
      getUsers(1).then((res) => {
        dispatch(setUsers(res?.data));
        setHasNextPage(res?.data?.meta?.hasNextPage)
        page2d = 1;
        dispatch(setInputValue(''));
      })
    })
  }

  useEffect(() => {
    getUsers(1).then((res) => {
      dispatch(setUsers(res?.data));
      setHasNextPage(res.data?.meta?.hasNextPage)
      dispatch(setInputValue(''));
    })
  }, [])

  useEffect(() => () => {
    page2d = 1
    dispatch(setUsers({}))
    setHasNextPage(false)
  }, [])

  const loadMore = () => {
    if (!!users?.results?.length) {
      if(users?.meta) {
      if (!loader) {
        page2d += 1;
        loader = true;
        getUsers(page2d).then((response) => {
          dispatch(setAddUsers(response?.data))
          loader = false;
          setHasNextPage(response?.data?.meta?.hasNextPage)
        })
      }}
    }
  }
  const clickedUser = (user) => {
    setUser(user);
    setIsShow(true);
  }
  const apiUsers = users?.results?.map((user) => {
    return (
      <tr key={user?.id}
          style={{backgroundColor:`${user?.deletedAt !== null ? 'iron' : 'white'}`}}

      >
        <th scope="row">
          <Media className="align-items-center">
            <div className="avatar-group">
              <img
                alt='img'
                className="avatar avatar-lg"
                style={{objectFit: "cover"}}
                src={user?.mainImageUrl || 'https://www.kindpng.com/picc/m/24-248253_user-profile-default-image-png-clipart-png-download.png'}
              />
            </div>
            <Media onClick={()=>clickedUser(user)}>
              <span className="mb-0 ml-2 text-sm" style={{cursor:'pointer'}}>
                {user?.firstName} {user?.lastName}
              </span>
            </Media>
          </Media>
        </th>
        <td>
          <span>
            {user.email}
          </span>
        </td>
        <td>
          <span>
            {user.balance}
          </span>
        </td>
        <td>
          <div className="d-flex align-items-center">
            <span className="mr-2">{user?.role}</span>
          </div>
        </td>
        <td>
          <span className="text-wrap">{user?.country}</span>
        </td>
        <td>
          <span>{user?.phoneNumber}</span>
        </td>
        <td>
          <span className="text-wrap">{moment(user?.createdAt).format('DD MMMM YYYY HH:mm')}</span>
        </td>
        <td>
          {user?.deletedAt !== null ?  <Button color="warning" data-toggle="modal" data-target={`#users-restore-modal${user.id}`}
                                               type="button">Restore</Button> : <Button color="danger" data-toggle="modal" data-target={`#users-modal${user.id}`}
                                                                                             type="button">Deactivate</Button> }

          <div className="modal fade" id={`users-modal${user.id}`} tabIndex="-1" role="dialog"
               aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content text-center">
                <div className="modal-header">
                  <h3 className="modal-title">Deactivate</h3>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="py-3 text-center mr-auto ml-auto">
                    <i className="ni ni-bell-55 ni-3x"/>
                    <div>
                      <h3 className="mt-4">Are you sure you want to <span style={{color: 'red'}}>DEACTIVATE</span> this
                        user? </h3>
                      <h3>{user?.firstName}{user?.lastName}</h3>
                    </div>
                  </div>

                </div>
                <div className="modal-footer d-flex">
                  <button type="button" className="btn btn-danger mr-auto" data-dismiss="modal">Cancel</button>

                  <button type="button" onClick={() => deactivateUser(user?.id)} data-dismiss="modal"
                          className="btn btn-success">Deactivate
                  </button>

                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id={`users-restore-modal${user.id}`} tabIndex="-1" role="dialog"
               aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content text-center">
                <div className="modal-header">
                  <h3 className="modal-title">Restore</h3>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="py-3 text-center mr-auto ml-auto">
                    <i className="ni ni-bell-55 ni-3x"/>
                    <div>
                      <h3 className="mt-4">Are you sure you want to <span style={{color: 'red'}}>RESTORE USER ?</span> this
                        user? </h3>
                      <h3>{user?.firstName}{user?.lastName}</h3>
                    </div>
                  </div>

                </div>
                <div className="modal-footer d-flex">
                  <button type="button" className="btn btn-danger mr-auto" data-dismiss="modal">Cancel</button>

                  <button type="button" onClick={() => restoreUserById(user?.id)} data-dismiss="modal"
                          className="btn btn-success">Restore
                  </button>

                </div>
              </div>
            </div>
          </div>

        </td>
      </tr>
    )
  })
  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
      </div>
      <AdminMessageSenderModal isShow={isShow} setIsShow={setIsShow} user={user}/>
      <InfiniteScroll
        loadMore={() => loadMore()}
        hasMore={hasNextPage}
        useWindow={true}
      >
        <Table className="align-items-center table-flush" responsive>

          <thead className="thead-light">
          <tr>
            <th scope="col">User Name:</th>
            <th scope="col">Email:</th>
            <th scope="col">Balance:</th>
            <th scope="col">Role:</th>
            <th scope="col">Country:</th>
            <th scope="col">Phone number:</th>
            <th scope="col">Create at:</th>
            <th scope="col">{`Total users(${users?.meta?.itemCount || 0})`}</th>
          </tr>
          </thead>

          <tbody>
          {apiUsers}
          </tbody>

        </Table>
        {(loader &&
          <div className="d-flex justify-content-center w-100" style={{width: '100%'}}>
            <img
              src={loaderGif}
              alt="loader"
              style={{height: "70px"}}
            />
          </div>)}
      </InfiniteScroll>
    </>
  )
}

export default Users

import axios from "axios";
import {getRefreshToken} from "./api";
import store from "../store/store";
import {setShowLoader} from "../store/admin";

const instance = axios.create({
  headers: {
    authorization: "Bearer " + localStorage.getItem('adminAccessToken')
  },
});

instance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('adminAccessToken')
    if (accessToken) {
      config.headers.authorization = 'Bearer ' + accessToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    store.dispatch(setShowLoader(false))
    return res;
  },
  async (err) => {
    store.dispatch(setShowLoader(false))
    const originalConfig = err.config;
    if (err.response.status === 400) {
      alert(err.response.data.message)
    }
    if (err.response.status === 401) {
      const refreshToken = localStorage.getItem('adminRefreshToken')

      getRefreshToken(refreshToken).then((rs) => {
        const {accessToken, refreshToken} = rs.data;
        localStorage.setItem('adminRefreshToken', refreshToken);
        localStorage.setItem('adminAccessToken', accessToken);
        return instance(originalConfig);
      }).catch((error) => {
        Promise.reject(error);
      })
      return Promise.reject(err);
    }
  }
);

export default instance;

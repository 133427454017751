import React, {useEffect, useState} from 'react'
import {
  Media,
  Table,
  Progress,
  Button,
} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {getCharities, deleteCharity, getCharityById, restoreCharity} from "../../services/api";
import {setAddCharities, setCharities, setCharity,setInputValue} from "../../store/admin";
import CreateCharityModal from "../../components/modalCreateCharity";
import EditCharity from "../../components/modalEditCharity";
import InfiniteScroll from 'react-infinite-scroller-improve';
import loaderGif from '../../assets/images/page-load-loader.gif'
import moment from "moment";

let page2d = 1;
let loader = false;

const Charities = () => {
  const dispatch = useDispatch();
  const charities = useSelector((state) => state.adminModule).charities;
  const [isOpen, setIsOpen] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [charityId, setCharityId] = useState('')
  const [charityToDelete, setCharityToDelete] = useState({});
  const [hasMore, setHasNextPage] = useState(Boolean)

  useEffect(() => {
    getCharities(1).then((response) => {
      dispatch(setCharities(response?.data))
      setHasNextPage(response?.data?.meta?.hasNextPage)
      dispatch(setInputValue(''));
    });
  }, [])

  const deactivateCharity = (id) => {
    deleteCharity(id).then(() => {
      getCharities(1).then((response) => {
        dispatch(setCharities(response?.data))
        setHasNextPage(response?.data?.meta?.hasNextPage)
        dispatch(setInputValue(''));
      })
    })
  }

  const restoreCharityById = (id) => {
    restoreCharity(id).then(() => {
      getCharities(1).then((res) => {
        dispatch(setCharities(res?.data))
        setHasNextPage(res?.data?.meta?.hasNextPage)
        page2d = 1;
        dispatch(setInputValue(''));
      })
    })
  }

  // const getCharitiesHandle = () => {
  //   getCharities(page2d).then((response) => {
  //     dispatch(setCharities(response?.data))
  //     setHasNextPage(response?.data?.meta?.hasNextPage)
  //     dispatch(setInputValue(''));
  //   });
  // }

  const loadMore = () => {
    if (!!charities?.results?.length) {
      if (!loader) {
        page2d += 1;
        loader = true;
        getCharities(page2d).then((response) => {
          dispatch(setAddCharities(response?.data))
          setHasNextPage(response?.data?.meta?.hasNextPage)
          loader = false;
        })
      }
    }
  }


  const apiCharities = charities?.results?.map((charity) => {
    return (
      <tr key={charity?.id} style={{backgroundColor: `${charity?.deletedAt !== null ? 'iron' : 'white'}`}}>

        <th scope="row">
          <Media className="align-items-center">
            <div
              style={{cursor: 'pointer'}}
              data-toggle="modal"
              data-target={`#user-modal${charity.user?.id}`}>

              <img
                alt='img'
                className="avatar avatar-lg"
                style={{objectFit: "cover"}}
                src={charity?.images?.find((image) => image.isMain === true)?.avatarUrl || 'https://www.kindpng.com/picc/m/24-248253_user-profile-default-image-png-clipart-png-download.png'}
              />
            </div>
          </Media>
        </th>
        <td>
          <div style={{maxWidth: '250px'}} className='text-wrap'>
            <span>{charity?.title} </span>
          </div>
        </td>
        <td>
                     <span>
                        {charity?.amount}
                     </span>
        </td>
        <td>
          <div style={{cursor: 'pointer'}} className="d-flex align-items-center">
            <div>
              <Progress
                min={0}
                max={charity?.amount}
                value={charity?.amountReceived}
                animated={true}
                barClassName="bg-danger"
              />
            </div>
          </div>
        </td>
        <td>
          <span>{moment(charity?.createdAt).format('DD MMMM YYYY HH:mm')}</span>
        </td>
        <td>
          <span>{moment(charity?.updatedAt).format('DD MMMM YYYY HH:mm')}</span>

        </td>
        <td>
          <Button type="button" disabled={charity?.deletedAt !== null} color="primary"
                  onClick={() => editCharity(charity.id)}>Edit</Button>
          {charity?.deletedAt !== null ? <Button color="warning" data-toggle="modal" data-target={`#restore-modal`} onClick={() => {
                setCharityToDelete(charity)
              }}
                                                 type="button">Restore</Button> :
            <Button color="danger" data-toggle="modal" data-target={`#decline-modal`} onClick={() => {
              setCharityToDelete(charity)
            }}
                    type="button">Deactivate</Button>}
        </td>
      </tr>
    )
  })
  const editCharity = (id) => {
    setCharityId(id)
    getCharityById(id).then((response) => {
      dispatch(setCharity(response.data))
      setIsShow(true);
    })

  }

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
      </div>
      <InfiniteScroll
        loadMore={() => loadMore()}
        hasMore={hasMore}
        useWindow={true}
        threshold={10}
      >
        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
          <tr>
            <th scope="col">Total({`${charities?.meta?.itemCount || 0}`})</th>
            <th scope="col">Title:</th>
            <th scope="col">Amount:</th>
            <th scope="col">Donations Received</th>
            <th scope="col">CreateAt:</th>
            <th scope="col">UpdateAt:</th>
            <th scope="col"><Button color="success"
                                    data-toggle="modal"
                                    type="button"
                                    onClick={() => setIsOpen(true)}
            >
              Add new Charity</Button>
            </th>
          </tr>
          </thead>
          <EditCharity page={page2d} id={charityId} isShow={isShow} setIsShow={setIsShow}/>
          <CreateCharityModal page={page2d} isOpen={isOpen} setIsOpen={setIsOpen}/>
          <tbody>

          {apiCharities}

          </tbody>
        </Table>
      </InfiniteScroll>

      <div className="modal fade" id={`decline-modal`} tabIndex="-1" role="dialog"
           aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content text-center">
            <div className="modal-header">
              <h3 className="modal-title">Deactivate</h3>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="py-3 text-center mr-auto ml-auto">
                <i className="ni ni-bell-55 ni-3x"/>
                <div>
                  <h3 className="mt-4">Are you sure you want to <span style={{color: 'red'}}>DEACTIVATE</span> this
                    Charity? </h3>
                </div>
              </div>

            </div>
            <div className="modal-footer d-flex">
              <button type="button" className="btn btn-danger mr-auto" data-dismiss="modal">Cancel</button>
              <button type="button" onClick={() => deactivateCharity(charityToDelete?.id)} data-dismiss="modal"
                      className="btn btn-success">Accept It
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id={`restore-modal`} tabIndex="-1" role="dialog"
           aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content text-center">
            <div className="modal-header">
              <h3 className="modal-title">Restore</h3>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="py-3 text-center mr-auto ml-auto">
                <i className="ni ni-bell-55 ni-3x"/>
                <div>
                  <h3 className="mt-4">Are you sure you want to <span style={{color: 'red'}}>RESTORE</span> this
                    Charity? </h3>
                </div>
              </div>

            </div>
            <div className="modal-footer d-flex">
              <button type="button" className="btn btn-danger mr-auto" data-dismiss="modal">Cancel</button>
              <button type="button" onClick={() => restoreCharityById(charityToDelete?.id)} data-dismiss="modal"
                      className="btn btn-success">Accept It
              </button>
            </div>
          </div>
        </div>
      </div>

      {(loader &&
        <div className="d-flex justify-content-center w-100">
          <img
            src={loaderGif}
            alt="loader"
            style={{height: "70px"}}
          />
        </div>)}
    </>
  )
}

export default Charities
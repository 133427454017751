import React, {useRef, useState, useEffect} from "react";
import {Modal, ModalHeader, ModalBody} from "reactstrap";
import InfiniteScroll from 'react-infinite-scroller-improve';
import loaderGif from '../assets/images/page-load-loader.gif'
import {getPublicDreamDonations} from '../services/api'
import {setModalDonations, setAddModalDonations} from '../store/admin'
import {useDispatch} from "react-redux";
let page2d = 1;
let loader = false;

const DreamMakersModal = ({donations, isShow, setIsShow, setUser, dreamId}) => {
  const [hasNextPage, setHasNextPage] = useState(null);
  useEffect(() => {
    setHasNextPage(donations?.meta?.hasNextPage);
  }, [donations?.meta?.hasNextPage]);

  let myRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => () => {
    page2d = 1
    dispatch(setModalDonations({}))
    setHasNextPage(null);
    loader = false;
  }, [])

  useEffect(() =>{
    page2d = 1
    dispatch(setModalDonations({}))
    setHasNextPage(null);
    loader = false;
  }, [dreamId])

  const loadMore = () => {
    if (donations?.results?.length) {
      if (!loader) {
        page2d += 1;
        loader = true;
        if (dreamId) {
          getPublicDreamDonations(dreamId, page2d).then((res) => {
            dispatch(setAddModalDonations(res.data))
            loader = false;
            setHasNextPage(res?.data?.meta?.hasNextPage)
          })
        }
      }
    }
  }

  return (
    <Modal isOpen={isShow} scrollable>
      <ModalHeader>
        <div>
          <button
            type="button"
            className="close-image mr-2"
            aria-label="Close"
            onClick={() => {
              setIsShow(false);
              dispatch(setModalDonations({}))
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <p className="card-title m-0 font-weight-bold">Users</p>
      </ModalHeader>
      <div ref={myRef}>
      <ModalBody style={{width: '100%', height: '700px', overflow: 'auto'}}>
        <InfiniteScroll
          loadMore={() => loadMore()}
          hasMore={hasNextPage}
          useWindow={false}
          threshold={10}
        >
          {donations?.results?.map((donate) => {
            return (
              <div key={donate?.id}
                   className='d-flex justify-content-start align-items-center flex-row flex-wrap border-bottom'>
                <a className='m-2' data-toggle="modal" data-target={`#user-modal`} type='button' onClick={() => {
                  dispatch(setModalDonations({}))
                  setUser(donate?.user);
                  setIsShow(false)
                }}>
                  <img
                    style={{objectFit: 'cover'}}
                    src={donate.user?.mainImageUrl || 'https://www.kindpng.com/picc/m/24-248253_user-profile-default-image-png-clipart-png-download.png'}
                    alt="story-img"
                    className="avatar avatar-lg"
                  />
                </a>
                <div className="flex-grow-1 ms-3">
                  <h5 className='m-0'>{donate?.user?.firstName || 'user deactivated'} {donate?.user?.lastName}</h5>
                </div>
              </div>)
          })}
          {(loader &&
            <div className="d-flex justify-content-center w-100" style={{width: '100%'}}>
              <img
                src={loaderGif}
                alt="loader"
                style={{height: "70px"}}
              />
            </div>)}
        </InfiniteScroll>
      </ModalBody>
      </div>
    </Modal>)
}
export default DreamMakersModal